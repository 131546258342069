import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { PlusCircle, BarChart2, Brain, ShoppingCart, Shield, Users } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="relative"
  >
    <dt>
      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#5C6BC0] text-white">
        <Icon className="h-6 w-6" />
      </div>
      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{title}</p>
    </dt>
    <dd className="mt-2 ml-16 text-base text-gray-500">{description}</dd>
  </motion.div>
);

const Home = () => {
  const features = [
    { icon: PlusCircle, title: "Flexible Payment Plans", description: "Split your purchases into 4 easy payments, tailored to your budget." },
    { icon: BarChart2, title: "Real-time Analytics", description: "Get insights into your payment trends and customer behavior." },
    { icon: Brain, title: "AI-Powered Underwriting", description: "Our advanced AI assesses creditworthiness more accurately, enabling better lending decisions." },
    { icon: Users, title: "Customer Insights", description: "Gain valuable data on customer preferences and behavior to tailor your offerings and improve satisfaction." },
    { icon: ShoppingCart, title: "Seamless Purchasing", description: "Enjoy a smooth and effortless way to purchase goods with our integrated payment solution." },
    { icon: Shield, title: "Enhanced Security", description: "State-of-the-art encryption and fraud detection to keep your transactions safe and secure." }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 0.5 }}
        className="bg-gradient-to-r from-[#4A90E2] to-[#FFCDD2] flex-grow flex flex-col items-center justify-center pt-20 pb-32"
      >
        <div className="max-w-7xl mx-auto flex flex-col items-center lg:flex-row px-4 sm:px-6 lg:px-8">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 lg:flex lg:flex-col lg:justify-center">
            <main className="mt-10 mx-auto max-w-7xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28 lg:flex lg:flex-col lg:items-start">
              <div className="sm:text-center lg:text-left">
                <motion.h1 
                  initial={{ y: -20, opacity: 0 }} 
                  animate={{ y: 0, opacity: 1 }} 
                  transition={{ delay: 0.2, duration: 0.5 }}
                  className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl drop-shadow-md"
                >
                  <span className="block xl:inline">Welcome to Fracta</span>
                </motion.h1>
                <motion.p 
                  initial={{ y: -20, opacity: 0 }} 
                  animate={{ y: 0, opacity: 1 }} 
                  transition={{ delay: 0.4, duration: 0.5 }}
                  className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 drop-shadow-md"
                >
                  Empowering businesses and consumers with AI Powered payment solutions.
                </motion.p>
                <motion.div 
                  initial={{ y: -20, opacity: 0 }} 
                  animate={{ y: 0, opacity: 1 }} 
                  transition={{ delay: 0.6, duration: 0.5 }}
                  className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
                >
                  <div className="rounded-md shadow">
                    <Link
                      to="/register"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#5C6BC0] hover:bg-[#FF9800] transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/login"
                      className="w-full flex items-center justify-center px-8 py-3 border border-[#5C6BC0] text-base font-medium rounded-md text-[#5C6BC0] bg-white hover:bg-gray-100 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Log in
                    </Link>
                  </div>
                </motion.div>
              </div>
            </main>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            {/* You can uncomment and use this image tag if you have an illustration */}
            {/* <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="/fracto-illustration.png"
              alt="Fracto illustration"
            /> */}
          </div>
        </div>
      </motion.div>

      {/* Features Section */}
      <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ delay: 0.5, duration: 0.5 }}
        className="py-12 bg-white"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-[#5C6BC0] font-semibold tracking-wide uppercase">Features</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              A better way to manage payments
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              Fracto provides innovative solutions for both businesses and consumers.
            </p>
          </div>

          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-x-8 md:gap-y-10">
              {features.map((feature, index) => (
                <FeatureCard key={index} {...feature} />
              ))}
            </dl>
          </div>
        </div>
      </motion.div>

      {/* Testimonials Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="py-12 bg-gray-50"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">What Our Customers Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { name: "Sarah L.", quote: "Fracta made it so easy to finance my new laptop. The approval was quick, and the repayment terms are fantastic!" },
              { name: "Michael R.", quote: "I love how Fracta uses AI for credit decisions. It felt fair and considered more than just my credit score." },
              { name: "Emily T.", quote: "The virtual card feature is a game-changer. I was able to make my purchase immediately after approval." }
            ].map((testimonial, index) => (
              <motion.div 
                key={index} 
                className="bg-white p-6 rounded-lg shadow-md"
                whileHover={{ scale: 1.05 }}
              >
                <p className="italic mb-4">"{testimonial.quote}"</p>
                <p className="font-semibold text-right">- {testimonial.name}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Call to Action Section */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 0.5 }}
        className="py-12 bg-[#4A90E2] text-white"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Experience Smarter Credit?</h2>
          <p className="text-xl mb-8">Join thousands of satisfied customers who've transformed their purchasing power with Fracta.</p>
          <div className="flex justify-center space-x-4">
            <Link to="/how-it-works" className="btn bg-white text-[#4A90E2] hover:bg-gray-100">Learn How It Works</Link>
            <Link to="/register" className="btn bg-[#FF9800] hover:bg-[#F57C00]">Create Account</Link>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Home;