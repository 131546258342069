import React, { useState, useContext } from 'react';
import { post, get } from '../../services/api';
import { DemoContext } from '../../DemoContext';

const CreditCheck = () => {
    const [panNumber, setPanNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [loanAmount, setLoanAmount] = useState('');
    const [loanTenure, setLoanTenure] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [demoMode, setDemoMode] = useState('none'); // 'none', 'simulated', 'real'
    const { isDemo, updateDemoCreditCheck } = useContext(DemoContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResult(null);
        setLoading(true);

        try {
            let response;
            if (isDemo || demoMode !== 'none') {
                response = await get('/api/demo-credit-score');
                if (isDemo) {
                    updateDemoCreditCheck(response.data);
                }
            } else {
                response = await post('/api/predict-credit-score', {
                    pan_number: panNumber,
                    date_of_birth: dateOfBirth,
                    loan_amount: parseFloat(loanAmount),
                    loan_tenure: parseInt(loanTenure)
                });
            }
            setResult(response.data);
        } catch (err) {
            setError('An error occurred during the credit check. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleTryDemo = (mode) => {
        setPanNumber('ABCDE1234F');
        setDateOfBirth('1990-01-01');
        setLoanAmount('50000');
        setLoanTenure('12');
        setDemoMode(mode);
        setResult(null);
        setError('');
    };

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'excellent':
            case 'approved':
                return 'text-green-600';
            case 'good':
            case 'conditionally approved':
                return 'text-blue-600';
            case 'fair':
                return 'text-yellow-600';
            case 'poor':
            case 'denied':
                return 'text-red-600';
            default:
                return 'text-gray-600';
        }
    };

    return (
        <div className="max-w-md mx-auto mt-24 p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold mb-6 text-center">AI-Powered Credit Eligibility Check</h2>
            
            {(isDemo || demoMode !== 'none') && (
                <div className="mb-4 p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
                    <p className="font-bold">Demo Mode Active</p>
                    <p>This is a simulated credit check.</p>
                </div>
            )}

            <div className="mb-6 flex justify-center space-x-4">
                <button 
                    onClick={() => handleTryDemo('simulated')}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    Try Simulated Demo
                </button>
                <button 
                    onClick={() => handleTryDemo('real')}
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Try Real AI Demo
                </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="panNumber" className="block text-sm font-medium text-gray-700">PAN Number</label>
                    <input 
                        id="panNumber"
                        type="text" 
                        value={panNumber} 
                        onChange={(e) => setPanNumber(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">Date of Birth</label>
                    <input 
                        id="dateOfBirth"
                        type="date" 
                        value={dateOfBirth} 
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="loanAmount" className="block text-sm font-medium text-gray-700">Loan Amount (₹)</label>
                    <input 
                        id="loanAmount"
                        type="number" 
                        value={loanAmount} 
                        onChange={(e) => setLoanAmount(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="loanTenure" className="block text-sm font-medium text-gray-700">Loan Tenure (months)</label>
                    <input 
                        id="loanTenure"
                        type="number" 
                        value={loanTenure} 
                        onChange={(e) => setLoanTenure(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Check Eligibility'}
                </button>
            </form>

            {error && <p className="mt-4 text-red-600">{error}</p>}

            {result && (
                <div className="mt-6 p-4 border rounded-md bg-gray-50">
                    <h3 className="text-lg font-semibold mb-2">Credit Check Result</h3>
                    <p className="font-medium">
                        Status: <span className={`font-normal ${getStatusColor(result.credit_status)}`}>
                            {result.credit_status}
                        </span>
                    </p>
                    <p className="font-medium">Credit Limit: <span className="font-normal">₹{result.credit_limit.toLocaleString()}</span></p>
                    <p className="font-medium">Credit Score: <span className="font-normal">{result.predicted_credit_score}</span></p>
                    <p className="mt-2 text-sm text-gray-600">
                        {(isDemo || demoMode !== 'none')
                            ? "This is a simulated demo result." 
                            : "This assessment is based on our AI-powered credit scoring model."
                        }
                    </p>
                </div>
            )}
        </div>
    );
};

export default CreditCheck;